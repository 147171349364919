//export const dimensions = {
//   sidebarWidth: 220,
//   sidebarCollapsedWidth: "60px",
//   sidebarTop: "90px",
//   sidebarCollapseButtonHeight: "24px",
//   heightAppBar: 48,
//   heightSearchList: "360px",
//   actionsHeight: "2.5rem",
//   treeNodeIndentationWidth: "15px",
//   treeNodeHeight: "32px",
//   generalPadding: "5px",
//   generalMargin: "5px",
// };

const margin = 8;
const topbarHeight = 90;
const breadCrumbHeight = 48;
const breadcrumbMargin = 32;
const appDrawerWidth = 312;
const appDrawerItemHeight = 45;
const pageDrawerWidth = 230;
const pageDrawerWidthSmall = 150;
const minimumPageWidth = 800;
const minimumPageHeight = 1200;
const homeGridHeight = 400;
// (windowSize.height - 10 - theme.dimensions.totalTopbarHeight) / 2;

export const dimensions = {
  topbarHeight: topbarHeight,
  breadCrumbHeight: breadCrumbHeight,
  totalTopbarHeight: breadCrumbHeight + 4 * margin + topbarHeight,
  pageDrawerWidth: pageDrawerWidth,
  pageDrawerWidthSmall: pageDrawerWidthSmall,
  appDrawerWidth: appDrawerWidth,
  appDrawerItemHeight: appDrawerItemHeight,
  totalAppDrawerWidth: appDrawerWidth,
  totalAppDrawerWithPageDrawer: appDrawerWidth + pageDrawerWidth + margin * 3,
  margin: margin,
  heightAppBar: topbarHeight,
  minimumPageWidth: minimumPageWidth,
  minimumPageHeight: minimumPageHeight,
  homeGridHeight: homeGridHeight,
};

export const getDimensions = () => dimensions;
